<template>
  <div v-if="scope.row.scale.type">
    <click-to-edit
      v-if="scope.row.scale.type === scaleTypesEnum.TYPE_QUALITY"
      v-model="scope.row.scale.target"
      placeholder="Внесите целевой показатель"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.target')"
      style="width: 100%"
      type="textarea"
      :word-limit="255"
      show-word-limit
      @input="saveElementPropChange(scope.row, 'scale', {target:$event})"
    ></click-to-edit>

    <click-to-edit
      v-else
      v-model="scope.row.scale.target"
      placeholder="Внесите целевой показатель"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.target')"
      style="width: 100%"
      type="input"
      :number-format="{}"
      @input="saveElementPropChange(scope.row, 'scale', {target:$event})"
    ></click-to-edit>
  </div>


  <div
    v-else
    style="color: #b1b1b2;"
  >
    Сначала выберите тип цели
  </div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import {variables} from "@/componentsCompany/smz/variables";
export default {
  name: 'element-target-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {
      scaleTypesEnum: variables.scaleTypesEnum,
    }
  },
  computed: {},
  methods: {

  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
